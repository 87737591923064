module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CQC Assured","short_name":"CQC Assured","start_url":"/","background_color":"#FAFAFA","theme_color":"#ECF0F2","icon":"static/favicon.ico","icon_options":{"purpose":"any maskable"},"legacy":true,"display":"standalone","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c3e96560882fbcab5fcfef3942208029"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
